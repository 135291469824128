import React, { Component } from 'react';
import { Button, Modal } from 'antd';
import type AlarmModel from './model';
import styles from './index.less';
import { observer } from 'mobx-react';

@observer
export default class Alarm extends Component<{ store: AlarmModel; }> {
  private renderModalChildren(): Partial<React.ReactNode> {
    const { hours, totalPrice, gotoYiLian } = this.props.store;

    if (![
      72,
      432,
    ].includes(hours)) {
      return (
        <div className={styles.modalChildren}>
          <h3>
            尊敬的依链客户您好：
          </h3>
          <p className={styles.body}>
            根据网仓应收账单系统提示，截止今日您有待支付金额
            <span className={styles.keyPoint}>
              {totalPrice}
              元
            </span>
            ，请您及时核对账单并支付， 账单欠款超过
            {hours === 360 ? '15' : '3'}
            天， 系统将自动停发。
          </p>
          <p>
            如资金周转压力较大，您也可以选择开通依链白条进行支付（如需开通，
            <Button
              className={styles.button}
              onClick={gotoYiLian}
              type="link"
            >
              点击跳转进入
            </Button>
            ）。
          </p>
        </div>
      );
    }

    return (
      <div className={styles.modalChildren}>
        <h3>
          尊敬的依链客户您好：
        </h3>
        <p className={styles.body}>
          根据网仓应收账单系统提示，截止今日您有待支付金额
          <span className={styles.keyPoint}>
            {totalPrice}
            元
          </span>
          ，目前欠款已超
          {hours === 432 ? '18' : '3'}
          日未结算，
          <span className={styles.keyPoint}>
            系统已自动停发
          </span>
          ，请尽快核对账单并支付。
        </p>
        <p>
          如资金周转压力较大，您也可以选择开通依链白条进行支付（如需开通，
          <Button
            className={styles.button}
            onClick={gotoYiLian}
            type="link"
          >
            点击跳转进入
          </Button>
          ）。
        </p>
      </div>
    );
  }

  render() {
    const { getModalProps, zIndex } = this.props.store;

    return (
      <div
        id="alarm"
        style={{
          position: 'absolute',
          zIndex,
        }}
      >
        <Modal
          getContainer={document.getElementById('alarm')}
          {...getModalProps}
        >
          {this.renderModalChildren()}
        </Modal>
      </div>

    );
  }
}
