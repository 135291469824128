import { Modal, Tabs } from 'antd';
import { inject, observer } from 'mobx-react';
import React from 'react';

import type { Props } from './interface';

const listPanel = (styles, list, type, parentId?, tabIndex?, callback?: () => void) => {
  return (
    <div className={styles.shortcutList}>
      {list.map((item) => (
        <div
          className={styles.item}
          key={item.id + type}
          onClick={!type && callback.bind(this, item)} // 快捷方式跳转对应iframe页面
        >
          <div className={styles.iconWrapper}>
            <span
              className={`${styles.icon} icon-${item.icon}`}
              style={{ color: type === 'ADD' ? '#6D6E78' : '#2387FA' }}
            />
            {type && (
              <span
                className={`${styles.operationIcon} icon-${type === 'ADD' ? 'home_tj' : 'home_sj'}`}
                onClick={callback.bind(this, item, type, parentId, tabIndex)}
              />
            )}
          </div>
          <span className={styles.name}>
            {item.name}
          </span>
        </div>
      ))}
    </div>
  );
};

@inject('dashboardStore')
@observer
export default class ShortCut extends React.Component<Partial<Props>> {
  render(): JSX.Element {
    const { styles } = this.props;
    const { shortCutEditData, shortCutData, isShowEdit, toggleShortcutEdit, handleShortCutEdit, handleShortCutSave, handleShortCutOpenTab } = this.props.dashboardStore;

    const items = shortCutEditData.surplus.map((item, index) => ({
      label: item.name,
      key: item.id.toString(),

      // @ts-ignore
      children: listPanel(styles, item.menus, 'ADD', item.id, index, handleShortCutEdit),
    }));
    return (
      <div
        className={styles.shortcut}
      >
        <div className={styles.content}>
          <div className={styles.header}>
            <span className={styles.title}>
              快捷入口
            </span>
            <span
              className={`${styles.icon} icon-home_bj`}
              onClick={toggleShortcutEdit.bind(this, true)}
            />

          </div>
          {// @ts-ignore
            listPanel(styles, shortCutData.current, null, null, null, handleShortCutOpenTab)
          }
        </div>

        {isShowEdit && (
          <Modal
            centered
            closable={false}
            onCancel={toggleShortcutEdit.bind(this, false)}
            onOk={handleShortCutSave}
            open
            title="快捷入口"
            width={600}
            wrapClassName={styles.shortCurtModalWrapper}
          >
            <div className={styles.currentChoose}>

              { // @ts-ignore
                listPanel(styles, shortCutEditData.current, 'DELETE', null, null, handleShortCutEdit)
              }

            </div>
            <div className={styles.reset}>
              <Tabs items={items}/>
            </div>
          </Modal>
        )}
      </div>
    );
  }
}
