import { Badge, Button, message, Modal, Progress } from 'antd';
import type { BaseData } from 'egenie-common';
import { request } from 'egenie-common';
import moment from 'moment';
import { nanoid } from 'nanoid';
import React from 'react';
import Dashboard from '../pages/dashboard/index';
import { api } from '../utils';
import { LayoutGuide, LayoutStore } from '../utils/layout';
import styles from './index.less';
import dashboardStore from './dashboard/dashboardStore';
import NotificationDrawerStore from './notificationDrawer/store';
import NoticeBadge from './components/noticeBadge';
import { SystemType } from './components/noticeBadge/constant';
import UnionGuide from '../utils/layout/unionGuide';

const notificationCenterStore = new NotificationDrawerStore({ parent: this });

// 上架跳转
export const SHELVE_URL = {
  '-100': (gmsGoodsId, shopId, pageId) => {
    return `/egenie-ts-vogue/shelvesNew/index?platformType=-100&gmsGoodsId=${gmsGoodsId}&shopId=${shopId}&pageId=${pageId}`;
  },
  '1': (gmsGoodsId, shopId, pageId) => {
    return `/egenie-ts-vogue/shelvesNew/index?platformType=1&gmsGoodsId=${gmsGoodsId}&shopId=${shopId}&pageId=${pageId}`;
  },
  '21': (gmsGoodsId, shopId, pageId) => {
    return `/egenie-ts-vogue/shelvesDouyin/index?platformType=21&gmsGoodsId=${gmsGoodsId}&shopId=${shopId}&pageId=${pageId}`;
  },
  '17': (gmsGoodsId, shopId, pageId) => {
    return `/egenie-ts-vogue/shelvesPdd/index?platformType=17&gmsGoodsId=${gmsGoodsId}&shopId=${shopId}&pageId=${pageId}`;
  },
  '22': (gmsGoodsId, shopId, pageId) => {
    return `/egenie-ts-vogue/shelvesKs/index?platformType=22&gmsGoodsId=${gmsGoodsId}&shopId=${shopId}&pageId=${pageId}`;
  },
  '32': (gmsGoodsId, shopId, pageId) => {
    return `/egenie-ts-vogue/shelvesDouyinProvider/index?platformType=32&gmsGoodsId=${gmsGoodsId}&shopId=${shopId}&pageId=${pageId}`;
  },
  '31': (gmsGoodsId, shopId, pageId) => {
    return `/egenie-ts-vogue/shelvesWxVideo/index?platformType=31&gmsGoodsId=${gmsGoodsId}&shopId=${shopId}&pageId=${pageId}`;
  },
};

const layoutStore = new LayoutStore({
  project: {
    name: '',
    value: 'egenie-erp-home',
  },
});
layoutStore.handleInit();

interface IState {
  isXuanKuan: boolean;
  showRestDialog: boolean;
  logisticFailed: boolean;
  staticData?;
  associatedShopId: number;// 是否是分销商，分销商关联的ID
  unMessageNum: number;// 未读消息数
}

interface Opera {
  id: string | number;
  name: string;
  callback?: () => void;
}

// 导航 示例
export default class LayoutTest extends React.Component {
  public state: IState = {
    isXuanKuan: false,
    showRestDialog: false,
    logisticFailed: false,
    staticData: {},
    associatedShopId: -1,
    unMessageNum: 0,
  };

  componentDidMount(): void {
    this.parserParams();
    request({ url: api.user })
      .then((res: { tenantType?; status: string; username; lastLoginTime; associatedShopId; }) => {
        const isXuanKuan = res.tenantType.includes('100015');
        const isERP = res.tenantType.includes('100001');
        const associatedShopId = res.associatedShopId;
        this.setState({
          isXuanKuan,
          associatedShopId,
        });
        const { queryLayoutGoodsVo } = dashboardStore;
        queryLayoutGoodsVo(associatedShopId || 0);

        // 开启广告打开该注释
        // leftTopADStore.getUserLoginNum && leftTopADStore.getUserLoginNum(res.username, res.lastLoginTime, isERP);
      });

    request({
      method: 'POST',
      url: '/api/saleorder/rest/monitor/abnormalOrders',
    })
      .then((req: BaseData<{ logisticFailed: number; }>) => {
        this.setState({ logisticFailed: req.data.logisticFailed > 0 });
      });
  }

  // 获取页面参数
  private parserParams = () => {
    const params = location.href.split('?')[1];
    const search = new URLSearchParams(params);
    const jumpTo = search.get('jumpTo') || '';
    const proxySendStatus = search.get('proxySendStatus');
    const mallSaleOrderId = search.get('mallSaleOrderId');
    const mallReturnOrderId = search.get('mallReturnOrderId');

    // 上架跳转所需要的参数
    const currentPlatformType = search.get('currentPlatformType');
    const gmsGoodsId = search.get('gmsGoodsId');
    const shopId = search.get('shopId');
    const pageId = nanoid(5);

    switch (jumpTo) {
      case 'myStyle':// 我的款式
        this.jumpToOtherPage('/egenie-ts-vogue/myStyle/index', '256', '我的款式');
        break;
      case 'myOrder': // 我的订单
        this.jumpToOtherPage('/egenie-ts-vogue/myOrder/index', '753', '我的订单');
        break;
      case 'favoriteStyles': // 收藏的款式
        this.jumpToOtherPage('/egenie-ts-vogue/favoriteStyles/index', '270', '收藏的款式');
        break;
      case 'myOrderDetail': // 衫海经订单详情
        this.jumpToOtherPage(`/egenie-ts-vogue/myOrderDetail/index?mallSaleOrderId=${mallSaleOrderId}`, 'myOrderDetail', '订单详情');
        break;
      case 'afterSaleOrder': // 售后订单
        this.jumpToOtherPage('/egenie-ts-vogue/afterSaleOrder/index', '754', '售后订单');
        break;
      case 'afterSaleOrderDetail': // 售后订单详情
        this.jumpToOtherPage(`/egenie-ts-vogue/afterSaleOrderDetail/index?mallReturnOrderId=${mallReturnOrderId}`, 'afterSaleOrderDetail', '售后单详情');
        break;
      case 'shopOrder': {
        let url = '/egenie-ts-vogue/shopOrder/index';
        if (proxySendStatus) {
          url += '?proxySendStatus=1';
        }
        this.jumpToOtherPage(url, '752', '店铺订单');
      }
        break;
      case 'shopManage':
        this.jumpToOtherPage('/egenie-ts-baseinfo/shopManage/index', '2695', '店铺管理');
        break;
      case 'shelvesNew':
        this.jumpToOtherPage(SHELVE_URL[currentPlatformType](gmsGoodsId, shopId, pageId), pageId, '上架');
        break;
      case 'smartStock':
        this.jumpToOtherPage('/egenie-ts-pms/smartStock/index', '60136', '智能备货');
        break;
      case 'repayment':
        this.jumpToOtherPage('/egenie-ts-payment/iousBill/index', 'baitiaozhangdan', '白条账单');
        break;
    }
  };

  // 跳转到别的页面
  private jumpToOtherPage = (url: string, tabId: string, tabName: string, icon?: string) => {
    window.top.egenie.openTab(url, tabId, tabName);
  };

  public userInfoLeft = (): JSX.Element => {
    const {
      isXuanKuan,
      associatedShopId,
    } = this.state;
    return (
      <div>
        {(associatedShopId === null || associatedShopId === 0) && (
          <span
            className={styles.shoppingCart}
            onClick={() => window.open(`${api.switchToXuankuan}?module=100015`)}
          >
            <span style={{
              color: '#1978ff',
              cursor: 'pointer',
            }}
            >
              衫海精首页
            </span>
            <span className={styles.splitIcon}>
              |
            </span>
          </span>
        )}
        <NoticeBadge systemType={SystemType.ERP_PC}/>
        <Badge
          dot={this.state.logisticFailed}
        >
          <span
            onClick={() => top.egenie.openTab('/egenie-ts-oms/monitor', 'monitor', '监控', 'd_inspect')}
            style={{
              color: '#1978ff',
              cursor: 'pointer',
            }}
          >
            监控
          </span>
        </Badge>
        <span className={styles.splitIcon}>
          |
        </span>
      </div>

    );
  };

  public userInfoRight = (): Opera[] => {
    return [
      {
        id: 'export',
        name: '导入导出任务中心',
        callback: () => top.egenie.openTab('/egenie-ts-baseinfo/exportList/index', 'export_task_center', '导入导出任务中心', 'zc_pfs'),
      },
      {
        id: 1,
        name: '余量查看',
        callback: () => {
          this.queryStatistics();
        },
      },
    ];
  };

  // 切换余量查看
  public toggleRestData = (flag: boolean, id?: number): void => {
    this.setState({ showRestDialog: flag });
    id && top.egenie.openTabId(id);
  };

  // 获取统计数据
  public queryStatistics = (): void => {
    request({
      method: 'post',
      url: api.tenantRechangeStatistics,
      data: {
        page: 1,
        pageSize: 20,
      },
    })
      .then((res: { data?; }) => {
        if (!res || !res.data) {
          message.error('获取余量数据失败');
          return;
        }
        this.setState({ staticData: res.data });
        this.toggleRestData(true);
      });
  };

  // 充值总天数
  public getTotalDay = (): number => {
    const { tenantRechargeVo } = this.state.staticData;
    if (!tenantRechargeVo) {
      return 0;
    }
    const {
      day,
      accountStartTime,
      overdrawDatetime,
    } = tenantRechargeVo;
    if (day <= 0 || !accountStartTime || !overdrawDatetime) {
      return 0;
    }
    const start = moment(accountStartTime);
    const end = moment(overdrawDatetime);
    const totalDays = end.diff(start, 'days');
    return day / totalDays * 100;
  };

  public byCount = (): JSX.Element => {
    const { rechargeStatisticsVo } = this.state.staticData;
    if (!rechargeStatisticsVo) {
      return null;
    }
    return (
      <>
        <div className={styles.totalOrderNum}>
          总单量：
          <span>
            {rechargeStatisticsVo.quantity}
          </span>
        </div>
        <div className={styles.progressWrapper}>
          <Progress
            percent={(rechargeStatisticsVo.restQuantity / rechargeStatisticsVo.quantity) * 100}
            showInfo={false}
            strokeLinecap="square"
            strokeWidth={18}
            trailColor="#2075ff33"
          />
        </div>
        <div className={styles.restOrderNum}>
          剩余单量：
          <span>
            {rechargeStatisticsVo.restQuantity}
          </span>
        </div>
      </>
    );
  };

  public byYear = (): JSX.Element => {
    const {
      tenantRechargeVo,
      rechargeType,
    } = this.state.staticData;
    if (!tenantRechargeVo) {
      return null;
    }
    const {
      day,
      accountStartTime,
      overdrawDatetime,
    } = tenantRechargeVo;
    const days = day >= 0 ? day : 0;
    const isNeedCharge = day < 30;
    return (
      <>
        <div className={styles.date}>
          <span className={styles.dateBegin}>
            开始时间：
            {accountStartTime}
          </span>
          <span>
            结束时间：
            {overdrawDatetime}
          </span>
        </div>
        <div className={styles.count}>
          <Progress
            format={() => ''}
            percent={this.getTotalDay()}
            strokeColor={isNeedCharge ? '#E90F24' : '#1978ff'}
            strokeLinecap="square"
            strokeWidth={14}
            type="circle"
            width={240}
          />
          <div className={styles.countContent}>
            <div className={styles.title}>
              剩余使用天数
            </div>
            <div className={styles.days}>
              {days}
              天
            </div>
          </div>
        </div>
      </>
    );
  };

  public restModal = (): JSX.Element => {
    const { rechargeType } = this.state.staticData;

    return (
      <Modal
        centered
        className={styles.restModalWrapper}
        footer={rechargeType === 1 ? null : [
          <Button
            key="submit"
            onClick={this.toggleRestData.bind(this, false, 2696)}
            type="primary"
          >
            了解详情
          </Button>,
        ]}
        onCancel={this.toggleRestData.bind(this, false, null)}
        open
        title="单量使用统计"
        width={rechargeType === 1 ? 600 : 450}
      >
        {rechargeType === 1 ? this.byYear() : this.byCount()}

      </Modal>
    );
  };

  public getLogoImg = (): JSX.Element => {
    return (
      <img
        onDragStart={(e) => {
          e.preventDefault();
        }}
        src={`${api.oss}/images/erpLogo.png`}
        style={{ width: '100%' }}
      />
    );
  };

  render(): JSX.Element {
    const { showRestDialog } = this.state;
    return (
      <div style={{ position: 'relative' }}>
        {/* <LeftTopAD/> */}
        <LayoutGuide
          defaultDashboard={<Dashboard/>}
          haveDashboard={false}
          logoImg={this.getLogoImg()}
          store={layoutStore}
          userInfoLeft={this.userInfoLeft()}
          userInfoRight={this.userInfoRight()}
        />
        <UnionGuide
          store={layoutStore}
        />
        {showRestDialog ? this.restModal() : null}
      </div>
    );
  }
}
