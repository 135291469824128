import { observer } from 'mobx-react';
import React, { useEffect, useState } from 'react';
import css from './index.less';
import { Modal } from 'antd';
import type { RuleModalProps } from './interface';
import { request } from 'egenie-common';

const ActiveRuleModal = observer((props: RuleModalProps) => {
  const [
    htmlContent,
    setHtmlContent,
  ] = useState<string>(null);

  useEffect(() => {
    queryHtmlById();
  }, []);

  const queryHtmlById = (): void => {
    request({
      url: '/api/boss/baseinfo/rest/agreement/anon/queryHtmlById',
      params: { id: 17 },
    }).then(({ data }) => {
      setHtmlContent(data);
    });
  };

  return (
    <Modal
      footer={null}
      maskClosable={false}
      width={700}
      {...props}
    >
      <div
        className={css.content}
        dangerouslySetInnerHTML={{ __html: htmlContent }}
      />
    </Modal>
  );
});

export default ActiveRuleModal;
