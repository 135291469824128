import { Button, Col, notification, Row } from 'antd';
import { inject, observer } from 'mobx-react';
import moment from 'moment';
import { ExclamationCircleFilled } from '@ant-design/icons';
import React, { useEffect } from 'react';
import { api } from '../../utils';
import type { Props, TabParam } from './interface';
import css from './index.less';

const Notification = observer((props: { value?: number | string; }) => {
  const { value } = props;
  const [
    api,
    contextHolder,
  ] = notification.useNotification();

  useEffect(() => {
    if (value) {
      openNotification();
    }
  }, [value]);

  const openNotification = () => {
    api.open({
      message: '平台发货失败提醒',
      icon: <ExclamationCircleFilled style={{ color: '#ff5835' }}/>,
      description: (
        <div className={css.notification}>
          <div className={css.notificationText}>
            当前有
            <span style={{ color: '#ff5835' }}>
              {value}
            </span>
            个订单平台发货失败，请及时处理
          </div>
          <div className={css.lookDetail}>
            <Button onClick={() => {
              window.top.egenie.openTab('/egenie-ts-oms/monitor', 'monitor', '监控', 'd_inspect');
            }}
            >
              查看详情
            </Button>
          </div>
        </div>
      ),
      duration: 0,
    });
  };

  return (
    <div>
      {contextHolder}
    </div>
  );
});

@inject('dashboardStore')
@observer
export default class TodoTask extends React.Component<Partial<Props>> {
  public handleTask = (item: TabParam): void => {
    window.top.egenie.openTab(item.url, item.id, item.name, item.icon);
  };

  public getDate = (): string => {
    // 结束日期 = 当前时间-前两天
    const currentTime = new Date().getTime();
    const surplusTime = currentTime - (24 * 2 * 60 * 60 * 1000);
    return moment(surplusTime).format('YYYY-MM-DD HH:mm:ss');
  };

  render(): JSX.Element {
    const { styles } = this.props;
    const { todoData } = this.props.dashboardStore;
    const todoListLeft = [
      {
        name: '订单处理',
        count: todoData.waitCheck,
        subName: '待审核',
        id: 505,
        url: '/egenie-ts-oms/order?is_checked-3-1=false',
        icon: 'icon_ddcl',
      },
      {
        name: '发货单3.0',
        subName: '待打印',
        count: todoData.waitPrint,
        id: 60104,
        url: '/egenie-ts-wms/wmsorder/index?courier_print_mark_state-4-1=1',
        icon: 'icon_fhd',
      },
      {
        name: '发货单3.0',
        subName: '待验货',
        count: todoData.waitReceive,
        id: 60104,
        url: '/egenie-ts-wms/wmsorder/index?is_lock-3-1=false&courier_print_mark_state-4-1=2',
        icon: 'icon_fhd',
      },
      {
        name: '订单处理',
        subName: '超过2天未发货',
        count: todoData.unPrintBefore2Day,
        icon: 'icon_ddcl',
        id: 505,
        url: `/egenie-ts-oms/order?date=sale_order_status.pay_time,,${this.getDate()}&courier_print_mark_state-4-14=1`,

      },
      {
        id: 'monitor',
        name: '监控',
        subName: '上次发货失败',
        count: todoData.undelivered,
        icon: 'd_inspect',
        url: '/egenie-ts-oms/monitor',
      },
      {
        name: '订单处理',
        subName: '采购完成未打印',
        count: todoData.purchasedUnPrint,
        id: 505,
        icon: 'icon_ddcl',
        url: `/egenie-ts-oms/order?purchase_state-4-13=2&courier_print_mark_state-4-14=1&is_suspended-3-1=false&date=sale_order_status.pay_time,,${moment(new Date())?.subtract(3, 'day')
          ?.endOf('day')
          ?.format('YYYY-MM-DD HH:mm:ss') || ''}`,
      },
    ];

    const todoListRight = [
      {
        name: '订单处理',
        count: todoData.notProxySendNum,
        subName: '未代发',
        id: 505,
        url: '/egenie-ts-oms/order?proxy_send_status-4-10=1',
        icon: 'icon_ddcl',
      },
      {
        name: '衫海精售后单',
        count: todoData.returnOrderNum,
        subName: '待退货',
        id: 757,
        url: '/egenie-ts-vogue/afterSaleOrder/index?status=1',
        icon: 'icon_dd',
      },
    ];
    return (
      <div
        className={styles.todotask}
      >
        <Notification value={todoData.undelivered}/>
        <img
          className={styles.imgbig}
          src={`${api.oss}/images/blueBlock.png`}
        />
        <div className={styles.content}>
          <div className={styles.title}>
            <span className={`${styles.icon} icon-home_dbsx`}/>
            <span className={styles.titleText}>
              待办事项
            </span>
          </div>
          <Row className={styles.list}>
            <Col span={18}>
              <Row>
                {todoListLeft.map((item) => (
                  <Col
                    className={styles.item}
                    key={item.subName}
                    onClick={this.handleTask.bind(this, item)}
                    span={8}
                  >
                    <span className={styles.count}>
                      {item.count || 0}
                    </span>
                    <span className={styles.name}>
                      {item.subName}
                    </span>
                  </Col>
                ))}
              </Row>
            </Col>
            <div className={styles.split}/>
            <Col
              span={5}
            >
              <Row>
                {todoListRight.map((item) => (
                  <Col
                    className={`${styles.item} ${styles.todoListRight}`}
                    key={item.subName}
                    onClick={this.handleTask.bind(this, item)}
                    span={24}
                  >
                    <span className={styles.count}>
                      {item.count || 0}
                    </span>
                    <span className={styles.name}>
                      {item.subName}
                    </span>
                  </Col>
                ))}
              </Row>
            </Col>
          </Row>

        </div>

      </div>
    );
  }
}
