import { ExclamationCircleFilled } from '@ant-design/icons';
import { observer, Provider } from 'mobx-react';
import React, { useEffect } from 'react';
import { DialogNotice, ElectricSheet } from '../components/index';
import Advertisement from './advertisement';
import ContactService from './contactService';
import dashboardStore from './dashboardStore';
import DeliveryProduct from './deliveryProduct';
import styles from './index.less';
import type { Props } from './interface';
import Notice from './notice';
import RelatedProduct from './relatedProduct';
import SaleCount from './saleCount';
import ShortCut from './shortcut';
import SystemUpdate from './systemUpdate';
import TodoTask from './todoTask';
import { Button, Modal } from 'antd';
import Alarm from './components/alarm';
import UnionPop from '../components/unionPop';

export class Dashboard extends React.Component<Props> {
  constructor(props) {
    super(props);
  }

  componentDidMount(): void {
    const {
      queryCommonInfo,
      queryOrderDelivery,
      queryTodoData,
      queryTopSale,
      queryReturnOrderNum,
      queryLayoutGoodsVo,
      getAlarm,
    } = dashboardStore;
    queryCommonInfo();
    queryOrderDelivery();
    queryTodoData();
    queryReturnOrderNum();

    // queryLayoutGoodsVo();
    queryTopSale();
    getAlarm();
  }

  render(): JSX.Element {
    return (
      <div className={styles.dashboard}>
        <RenderWarnModal/>
        <Alarm store={dashboardStore.alarmModel}/>
        <Provider dashboardStore={dashboardStore}>
          <div className={`${styles.panel} ${styles.line1}`}>
            <TodoTask styles={styles}/>
            <ShortCut styles={styles}/>
            <Advertisement styles={styles}/>
          </div>
          <div className={`${styles.panel} ${styles.line2}`}>
            <DeliveryProduct styles={styles}/>
            <SaleCount styles={styles}/>
            <Notice styles={styles}/>
          </div>
          <div className={`${styles.panel} ${styles.line3}`}>
            <RelatedProduct styles={styles}/>
            <ContactService styles={styles}/>
            <SystemUpdate styles={styles}/>
          </div>
        </Provider>
        <ElectricSheet/>
        <DialogNotice/>
        <UnionPop/>
      </div>
    );
  }
}

export const RenderWarnModal = observer(() => {
  const {
    showWarnModal,
    warnContent,
    getWarn,
    hiddenModal,
  } = dashboardStore;

  useEffect(() => {
    getWarn();
  }, []);

  const jumpToPayPage = () => {
    hiddenModal();
    top.egenie.openTab('/egenie-ts-payment/iousBill/index', 'baitiaozhangdan', '白条账单');
  };

  return (
    <Modal
      centered
      footer={(
        <Button
          onClick={jumpToPayPage}
          type="primary"
        >
          立即还款
        </Button>
      )}
      maskClosable={false}
      onCancel={hiddenModal}
      open={showWarnModal}
      title={(
        <div className={styles.warnModalTitle}>
          <ExclamationCircleFilled className={styles.warnModalIcon}/>
          依链白条还款提醒
        </div>
      )}
      width={337}
    >
      <div dangerouslySetInnerHTML={{ __html: warnContent }}/>
    </Modal>
  );
});

export default Dashboard;
