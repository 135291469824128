import { Button, message, Modal, Radio } from 'antd';
import type { BaseData } from 'egenie-common';
import { request } from 'egenie-common';
import React, { useEffect, useState } from 'react';
import { api } from '../../../utils';
import ActiveRuleModal from './activeRuleModal';
import styles from './index.less';
import { inject, observer } from 'mobx-react';

const UnionPop = inject('layoutStore')((props) => {
  const { getUserInfo } = props.layoutStore;
  const [
    visible,
    setVisible,
  ] = useState(false);// 邀约弹出面板
  const [
    ruleVisible,
    setRuleVisible,
  ] = useState(false);// 公约内容面板是否显示
  const [
    isChecked,
    setIsChecked,
  ] = useState(false);// 单选框是否选中状态
  const [
    submitting,
    setSubmitting,
  ] = useState(false);
  useEffect(() => {
    const req = async() => {
      const results = await request<BaseData<boolean>>({ url: '/api/tenant/clothesUnion/isPushAd' });
      setVisible(results?.data);
    };
    req();
  }, []);
  return (
    <Modal
      centered
      className={styles.pop}
      closable={false}
      footer={null}
      open={visible}
      width={390}
    >
      <img
        alt=""
        className={styles.background}
        onClick={() => {
          window.open('/egenie-clothes-union/active/index');
        }}
        src={`${api.oss}/images/popBg.png`}
      />
      <div className={styles.container}>
        <div className={styles.radioBtnContainer}>
          <Radio onChange={(e) => {
            setIsChecked(e.target.checked);
          }}
          >
            已阅读并同意
          </Radio>
          <div
            className={styles.rules}
            onClick={() => setRuleVisible(true)}
          >
            《不缺货联盟公约》
          </div>
        </div>
        <div className={styles.btns}>
          <Button
            className={styles.cancelBtn}
            loading={submitting}
            onClick={() => {
              setVisible(false);
            }}
          >
            取消
          </Button>
          <Button
            className={styles.joinBtn}
            disabled={!isChecked}
            loading={submitting}
            onClick={() => {
              setSubmitting(true);
              request({
                url: '/api/tenant/clothesUnion/joinIn',
                params: { type: 1 },
              }).then(() => {
                setVisible(false);
                getUserInfo();
                message.success('加入成功');
              })
                .finally(() => {
                  setSubmitting(false);
                });
            }}
            type="primary"
          >
            同意加入
          </Button>
        </div>
      </div>
      <ActiveRuleModal
        onCancel={() => setRuleVisible(false)}
        open={ruleVisible}
      />
    </Modal>
  );
});
export default observer(UnionPop);
