
import { Input, Button, Form } from 'antd';

import { inject, observer } from 'mobx-react';
import React from 'react';
import { phoneReg } from 'egenie-common';
import { api } from '../../utils';
import type { Props } from './interface';

@inject('dashboardStore')
@observer
export default class contactService extends React.Component<Props> {
  render(): JSX.Element {
    const { styles } = this.props;
    const { feedBackformRef, handleSubmit } = this.props.dashboardStore;
    return (
      <div className={styles.contactService}>
        <div className={styles.service}>
          <img
            className={styles.headPortrait}
            onDragStart={(e) => e.preventDefault()}
            src={`${api.oss}/images/headPortrait.png`}
          />
          <div className={styles.title}>
            联系客服
          </div>
          <div className={styles.serviceBody}>
            <div className={styles.shapeHomeBody}>
              <img
                className={styles.shapeHome}
                onDragStart={(e) => e.preventDefault()}
                src={`${api.oss}/images/shapeHome.png`}
              />
              <div className={styles.shapeHomeTitle}>
                微信扫码联系客服哦~
              </div>
            </div>
            <div className={styles.customerService}>
              <div>
                <img
                  onDragStart={(e) => e.preventDefault()}
                  src={`${api.oss}/images/customerService1.png`}
                />
                <span>
                  微信客服1
                </span>
              </div>
              <div>
                <img
                  onDragStart={(e) => e.preventDefault()}
                  src={`${api.oss}/images/customerService2.png`}
                />
                <span>
                  微信客服2
                </span>
              </div>
            </div>
          </div>
        </div>
        <div className={styles.feedBack}>
          <div className={styles.title}>
            建议反馈
          </div>
          <Form
            onFinish={handleSubmit}
            ref={feedBackformRef}
            requiredMark={false}

          >
            <Form.Item
              name="feedBacktext"
              rules={[
                {
                  required: true,
                  message: '请输入您的问题',
                },
              ]}
            >
              <Input.TextArea
                className={styles.text}
                placeholder="请输入您的问题"
                rows={3}

              />
            </Form.Item>
            <div className={styles.feedBackphone}>
              <Form.Item
                className={styles.phone}
                label="联系电话"
                labelCol={{ span: 24 }}
                name="feedBackphone"
                rules={[
                  {
                    required: true,
                    message: '请输入手机号',
                  },
                  {
                    pattern: phoneReg,
                    message: '请输入正确手机号',
                  },
                ]}
              >
                <Input
                  maxLength={11}
                  placeholder="请输入11位有效手机号"
                  type="phone"
                />
              </Form.Item>
              <Form.Item>
                <Button
                  className={styles.submit}
                  htmlType="submit"
                  type="primary"
                >
                  提交
                </Button>
              </Form.Item>
            </div>
          </Form>

        </div>

      </div>
    );
  }
}
