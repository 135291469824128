
import { InputNumber, message } from 'antd';
import type { BaseData } from 'egenie-common';
import { request } from 'egenie-common';
import { action, computed, observable } from 'mobx';
import { nanoid } from 'nanoid';
import React from 'react';
import { api } from '../../../utils';
import type { WayBillItem } from '../../baseInfoHome/dashboard/interface';
import type { Bill, NewTab, Response, TenantRechargeExpire } from './interface';
import type { ColumnsType } from 'antd/es/table';
import { Observer } from 'mobx-react';

export default class ElectricSheetStore {
  @observable public isPrewarn = false; // 预警

  @observable public tenantRechargeExpire: TenantRechargeExpire = {};

  @observable public billNotice: Bill[] = [];

  @computed public get zIndex(): number {
    return window.top.egenie.activeTabKey ? -1 : 1;
  }

  // 快捷入口跳转
  public handleShortCutOpenTab = (item: NewTab): void => {
    // 销售开单等菜单要携带仓库ID
    const warehouseMenu = sessionStorage.getItem('warehouseMenu');
    const warehouseList = warehouseMenu ? JSON.parse(warehouseMenu) : [];
    const currentWareHouse = sessionStorage.getItem('currentWareHouse');
    const url = warehouseList.includes(Number(item.id)) ? `${item.url}?shopAndWarehouseId=${currentWareHouse}` : item.url;
    window.top.egenie.openTab(url, item.id, item.name, item.icon);
  };

  // 获取电子面单
  public queryBillNotice = action(() => {
    request({ url: api.getWaybillNotice }).then((res: Response) => {
      this.billNotice = res.data;
    });
  });

  @observable public wayBillRows: WayBillItem[] = [];

  @observable.ref public wayBillColumns: ColumnsType<WayBillItem> = [
    {
      title: '序号',
      dataIndex: '_index',
      width: 60,
    },
    {
      title: '快递公司',
      dataIndex: 'courierName',
      width: 150,
    },
    {
      title: '面单渠道',
      dataIndex: 'channel',
      width: 120,
    },
    {
      title: '店铺名称',
      dataIndex: 'shopName',
      width: 150,
    },
    {
      title: '网点信息',
      width: 250,
      dataIndex: 'branchName',
      render: (_, row) => (row.branchName ? `${row.branchName}(${row.branchCode || ''})` : ''),
    },
    {
      title: '剩余单量',
      width: 80,
      dataIndex: 'balance',
    },
    {
      title: '预警值',
      dataIndex: 'prewarnValue',
      width: 120,
      render: (_, row) => (
        <Observer>
          {
            () => (
              <InputNumber
                max={99999999}
                min={0}
                onChange={(value) => row.prewarnValue = value}
                placeholder="请输入预警值"
                size="small"
                value={row.prewarnValue}
              />
            )
          }
        </Observer>
      ),
    },
  ].map((item) => ({
    ...item,
    ellipsis: true,
  }));

  // 预警成功按钮
  @action public onPrewarnOk = async() => {
    try {
      this.isPrewarn = true;
      await request<BaseData>({
        method: 'POST',
        url: '/api/wms/rest/waybill/updatePrewarnValue',
        data: { prewarnList: this.wayBillRows },
      });
      message.success('预警设置成功');
      this.onPrewarnCancel();
    } catch (e) {
      console.error(e);
    }
  };

  @action public onPrewarnCancel = () => {
    this.isPrewarn = false;
    this.wayBillRows = [];
  };

  @action public onWithdrawal = async() => {
    try {
      this.isPrewarn = true;
      const req = await request<BaseData<any[]>>({
        method: 'POST',
        url: '/api/wms/rest/waybill/queryWayBillBalance',
        data: { channel: '' },
      });
      this.wayBillRows = req.data.map((item, index) => {
        return {
          ...item,
          _id: nanoid(),
          _index: index + 1,
        };
      });
    } catch (e) {
      console.error(e);
    }
  };

  @action public getRechargeStatistics = async(): Promise<void> => {
    try {
      const res: BaseData<TenantRechargeExpire> = await request({ url: '/api/tenant/tenantRecharge/statistics' });
      this.tenantRechargeExpire = res.data;
    } catch (err) {
      console.error(err);
    }
  };
}

