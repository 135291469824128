import { Table, Button, Modal, notification } from 'antd';
import { observer } from 'mobx-react';
import moment from 'moment';
import React from 'react';
import styles from './index.less';
import ElectricSheetStore from './store';

@observer
export class ElectricSheet extends React.Component {
  componentDidMount(): void {
    const { getRechargeStatistics, queryBillNotice } = this.store;
    getRechargeStatistics();
    queryBillNotice();
  }

  private store = new ElectricSheetStore();

  public handleGoToDetail = (): void => {
    const { handleShortCutOpenTab } = this.store;
    handleShortCutOpenTab({
      id: 60122,
      name: '快递公司',
      url: '/egenie-ts-baseinfo/courierCompanyErp/index',
      icon: 'icon_kdgs',
    });
    notification.close('electricSheet');
  };

  // 电子面单
  public electricSheet = (): void => {
    const { billNotice } = this.store;
    const description = () => (
      <div className={styles.eletricDesc}>
        {billNotice.map((item) => (
          <div
            className={styles.item}
            key={item.branchCode}
          >
            <span>
              快递公司:
              {item.courierName}
            </span>
            <span className={styles.text}>
              网点编码:
              {item.branchCode}
            </span>
            <span>
              余额:
              {item.waybillCount}
            </span>
          </div>
        ))}
      </div>
    );
    const buttonGroups = () => (
      <div>
        <Button
          className={styles.eletricSetting}
          onClick={this.store?.onWithdrawal}
          type="default"
        >
          提醒设置
        </Button>
        <Button
          className={styles.eletricSetting}
          onClick={this.handleGoToDetail}
          type="default"
        >
          查看详情
        </Button>
      </div>
    );

    notification.open({
      message: '电子面单余额不足提醒',
      description: description(),
      btn: buttonGroups(),
      key: 'electricSheet',
      duration: null,
      className: 'dashboardNotice',
      top: 64,
      getContainer: () => document.getElementById('electricSheet'),
    });
  };

  // 租户到期提醒
  public expireTips = (): void => {
    const { tenantRechargeExpire } = this.store;
    const { notify, overdrawDatetime, rechargeType, recentDaysQuantity, restQuantity } = tenantRechargeExpire;

    const description = (): JSX.Element => {
      const endDate = moment(overdrawDatetime).format('YYYY-MM-DD');
      return (
        <div>
          {
            rechargeType === 1 ? (
              <div>
                您的系统到期时间为
                <span style={{ color: '#ff0000' }}>
                  {endDate}
                </span>
                ，为了不影响正常使用，请及时续费充值。
              </div>
            ) : (
              <div>
                您的系统剩余单量为
                <span style={{ color: '#ff0000' }}>
                  {restQuantity}
                </span>
                单，近15天发货量为
                <span style={{ color: '#ff0000' }}>
                  {recentDaysQuantity}
                </span>
                ，为了不影响正常使用，请及时续费充值。
              </div>
            )
          }
        </div>
      );
    };

    const onClick = () => {
      window.top.egenie.openTab('/egenie-ts-baseinfo/systemOrderStatistics/index', 2696, '系统单量统计');
    };

    const buttonGroups = (): JSX.Element => {
      return (
        <div>
          <Button
            className={styles.eletricSetting}
            onClick={onClick}
            type="default"
          >
            查看详情
          </Button>
        </div>
      );
    };

    notify && notification.open({
      message: '系统单量预警',
      description: description(),
      btn: buttonGroups(),
      key: 'expireTips',
      duration: null,
      className: 'dashboardNotice',
      top: 64,
      getContainer: () => document.getElementById('electricSheet'),
    });
  };

  // 电子面单余额预警设置
  public renderModal = () => {
    const { wayBillColumns, wayBillRows, isPrewarn, onPrewarnOk, onPrewarnCancel } = this.store;
    return (
      <Modal
        centered
        getContainer={document.getElementById('electricSheet')}
        onCancel={onPrewarnCancel}
        onOk={onPrewarnOk}
        open={isPrewarn}
        title="电子面单余额预警设置"
        width={1000}
      >
        <Table
          columns={wayBillColumns}
          dataSource={wayBillRows}
          pagination={false}
          rowKey="_id"
          scroll={{
            y: 500,
            x: '100%',
          }}
          size="small"
        />
      </Modal>
    );
  };

  render(): JSX.Element {
    const { billNotice, tenantRechargeExpire: { notify, overdrawDatetime, rechargeType, recentDaysQuantity, restQuantity }, zIndex } = this.store;

    let flag = false;
    if (notify) {
      if (rechargeType === 1) {
        const nowDate = moment().format('YYYY-MM-DD');
        const endDate = moment(overdrawDatetime).format('YYYY-MM-DD');
        const diff = moment(endDate).diff(nowDate, 'day');
        flag = Number(diff) < 15;
      } else if (rechargeType === 2) {
        flag = Number(recentDaysQuantity) > Number(restQuantity);
      }
    }
    return (
      <div
        className={styles.electricSheet}
        id="electricSheet"
        style={{ zIndex }}
      >
        {this.renderModal()}
        {flag && this.expireTips()}
        {billNotice && billNotice.length > 0 && this.electricSheet()}
      </div>
    );
  }
}
