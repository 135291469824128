import { inject, observer } from 'mobx-react';
import React from 'react';
import { api } from '../../utils';
import type { AdvertisementItem, Props } from './interface';
import { Carousel } from 'antd';

@inject('dashboardStore', 'layoutStore')
@observer
export default class Advertisement extends React.Component<Props> {
  public state = { showCarouselIcon: false };

  private carouselRef;

  private handleClick = () => {
    const { goodsVendorId } = this.props.dashboardStore;
    if (goodsVendorId) {
      window.open(`${api.switchToXuankuan}?module=100015&forwardUrl=/egenie-ts-vogue/distributor/stallDetail/index?vendorId=${goodsVendorId}`);
    } else {
      window.open(`${api.switchToXuankuan}?module=100015&forwardUrl=/egenie-ts-vogue/homePage/index&jumpTo=guessULike`);
    }
  };

  public linkToClothUnion = () => {
    window.open('/egenie-clothes-union/active/index');
  };

  private renderFinance = (): JSX.Element => {
    const { styles } = this.props;
    return (
      <div
        className={styles.financeBox}
        onClick={() => {
          window.open('/egenie-webapp-pay/accountCenter/financeDetail');
        }}
      >
        <img
          className={styles.financeBgImg}
          src={`${api.oss}/images/posFinanceBanner.png`}
        />
      </div>
    );
  };

  private renderSHJ = (): JSX.Element => {
    const { styles } = this.props;
    const {
      setDomRef,
      picList,
      goodsVendorId,
    } = this.props.dashboardStore;

    return (
      <div
        className={styles.shjBox}
        onClick={this.handleClick}
        ref={setDomRef}
      >
        <img
          className={styles.bgImg}
          src={`${api.oss}/images/transparentBubble.png`}
        />
        <div className={styles.title}>
          衫海精选款平台
          <span className={styles.circle}>
            <i className="icon-home_xq"/>
          </span>
        </div>
        <div className={styles.text}>
          {goodsVendorId ? '一件铺货、履约保障、无忧退货' : '全国货源、一件铺货、履约保障、无忧退货'}
        </div>
        <div className={styles.picList}>
          {
            picList.map((item) => {
              return (
                <img
                  className={styles.pic}
                  key={item}
                  src={item}
                />
              );
            })
          }
        </div>
      </div>
    );
  };

  public renderClothesUnion = (): JSX.Element => {
    const { styles } = this.props;
    return (
      <div
        className={styles.clothesUnionBox}
        data-id={JSON.stringify({
          name: '不缺货联盟活动页',
          jump: '/egenie-clothes-union/active/index',
          businessType: 'union',
        })}
        onClick={this.linkToClothUnion}
      >
        <img
          className={styles.clothesUnionContent}
          src={`${api.oss}/images/guanggao1.png`}
        />
        <img
          className={styles.clothesUnionBg}
          src={`${api.oss}/images/guanggao1icon.png`}
        />
      </div>
    );
  };

  private renderCarousel = (list: AdvertisementItem[]): JSX.Element => {
    const { styles } = this.props;
    const showIcon = this.state.showCarouselIcon;
    return (
      <div
        className={styles.carouselBox}
        onMouseEnter={() => {
          this.setState({ showCarouselIcon: true });
        }}
        onMouseLeave={() => {
          this.setState({ showCarouselIcon: false });
        }}
      >
        <img
          className={styles.bannerLeftIcon}
          onClick={() => {
            showIcon && this.carouselRef.prev();
          }}
          src={`${api.oss}/images/bannerRightIcon.png`}
          style={{
            opacity: showIcon ? 1 : 0,
            width: '22px',
          }}
          width={22}
        />

        <img
          className={styles.bannerRightIcon}
          onClick={() => {
            showIcon && this.carouselRef.next();
          }}
          src={`${api.oss}/images/bannerLeftIcon.png`}
          style={{
            opacity: showIcon ? 1 : 0,
            width: '22px',
          }}
          width={22}
        />

        <Carousel
          autoplay
          ref={(el) => this.carouselRef = el}
          style={{ height: '100%' }}
        >
          {
            list.map((item) => {
              return item?.render();
            })
          }
        </Carousel>
      </div>
    );
  };

  // 广告列表
  public advertisementList: AdvertisementItem[] = [
    {
      name: 'renderClothesUnion', // 不缺货联盟广告位
      render: this.renderClothesUnion,
    },

    // {
    //   name: 'shj', // 衫海精广告位
    //   render: this.renderSHJ,
    // },
    {
      name: 'finance', // 金融广告位
      render: this.renderFinance,
    },
  ];

  private renderOneCont = (list: AdvertisementItem[]): JSX.Element => {
    return list[0]?.render();
  };

  render(): JSX.Element {
    const {
      isJoinClothesUnion,
      showBannerFlag,
    } = this.props.layoutStore;
    const { styles } = this.props;
    let renderAdvertisementList = [].concat(this.advertisementList);

    // 已加入联盟和后台标记不显示联盟就过滤 联盟banner图
    if (isJoinClothesUnion || !showBannerFlag) {
      renderAdvertisementList = this.advertisementList.filter((item) => item.name !== 'renderClothesUnion');
    }

    return (
      <div className={styles.advertisement}>
        {
          renderAdvertisementList?.length <= 1 ? this.renderOneCont(renderAdvertisementList) : this.renderCarousel(renderAdvertisementList)
        }
      </div>
    );
  }
}
